import React from "react";
import LinkButton from "../shared/LinkButton";
import ProyekLoading from "../shared/ProyekLoading";

const Description = ({ data, loading, type }) => {
  return (
    <section className="section-intro" style={{ position: "relative" }}>
      <div className="container">
        {loading ? (
          <ProyekLoading />
        ) : (
          <div className="flex">
            <div className="col content">
              <div className="section-title">
                {type === "interior" && "Tentang"}
                <span style={{ marginLeft: 0 }}>
                  {type === "proyek" ? data.name : <span> {data.nama}</span>}
                </span>
                <div className="subtitle">
                  {type === "proyek" ? data.subtitle : data.tagline}
                </div>
              </div>
              <div>
                <div className="desc proyek">{data.desc || data.deskripsi}</div>
              </div>
              {type === "proyek" && (
                <LinkButton
                  label="Hitung Simulasi KPR"
                  href="https://ppdpp.id/kalkulator-kpr-flpp/"
                  blank
                  variant="outlined"
                />
              )}
            </div>
            <div className="col image">
              <img
                src={
                  type === "proyek"
                    ? data.potrait.sizes["medium_large"]
                    : data.header.sizes["medium_large"]
                }
                alt="Rumah"
              />
            </div>
          </div>
        )}
      </div>
      <div className="white-overlay" />
    </section>
  );
};

export default Description;
