import React from "react";
import Icon from "./Icon";

const Home = () => {
  return (
    <Icon>
      <svg viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 14.8167H14V22.225H10V14.8167Z" fill="inherit" />
        <path
          d="M20.42 10.7738L12.71 2.43413C12.617 2.33494 12.5064 2.2562 12.3846 2.20247C12.2627 2.14874 12.132 2.12108 12 2.12108C11.868 2.12108 11.7373 2.14874 11.6154 2.20247C11.4936 2.2562 11.383 2.33494 11.29 2.43413L3.57999 10.7844C3.39343 10.9834 3.24609 11.2197 3.14652 11.4795C3.04695 11.7393 2.99715 12.0175 2.99999 12.2978V21.1666C2.99922 21.7084 3.19477 22.2299 3.54637 22.6236C3.89797 23.0174 4.37885 23.2535 4.88999 23.2833H7.99999V13.7583C7.99999 13.4776 8.10535 13.2084 8.29289 13.0099C8.48042 12.8115 8.73478 12.7 8.99999 12.7H15C15.2652 12.7 15.5196 12.8115 15.7071 13.0099C15.8946 13.2084 16 13.4776 16 13.7583V23.2833H19.11C19.6211 23.2535 20.102 23.0174 20.4536 22.6236C20.8052 22.2299 21.0008 21.7084 21 21.1666V12.2978C21.0008 11.7294 20.7928 11.1829 20.42 10.7738V10.7738Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Home;
