import React from "react";
import LinkButton from "./LinkButton";
import Whatsapp from "../../assets/img/icon/Whatsapp";

const Offer = () => {
  return (
    <section className="section-intro section-offer">
      <div className="white-overlay" />
      <div className="container" style={{ position: "relative", zIndex: 1 }}>
        <div className="section-title">
          Jangan Lewatkan Penawaran yang Menarik
        </div>
        <LinkButton
          icon={<Whatsapp />}
          blank
          href="https://api.whatsapp.com/send?phone=6285888073655&text=Terima%20kasih%20telah%20menghubungi%20Perumahan%20Sativa%20Podomoro.%20Rumah%20Type%2047%2F66.%20Rumah%20Komersil%20Harga%20Subsidi.%0D%0APesan%20anda%20akan%20segera%20kami%20proses%20harap%20sabar%20menunggu%20balasan%20%F0%9F%98%8A%0D%0A%0D%0AKepuasan%20Anda%20Kebahagiaan%20kami%20Terimakasih%20%F0%9F%98%8A%0D%0A%0D%0ASativa%20Podomoro%0D%0AYour%20Living%20Residential%20%F0%9F%8C%BF"
          variant="primary"
          label="Hubungi Kami"
        />
      </div>
    </section>
  );
};

export default Offer;
