import React from "react";

const ProyekLoading = () => {
  return (
    <div className="container lp-loader">
      <div className="section-latest-project">
        <div>
          <div className="content-loader lp-loader-title" />
          <div className="content-loader lp-loader-content" />
        </div>
        <div>
          <div className="content-loader lp-loader-foto" />
        </div>
      </div>
    </div>
  );
};

export default ProyekLoading;
