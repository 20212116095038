import React from "react";
import Icon from "./Icon";

const Garden = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8 12.8C20.8 18.096 17.296 22.4 12 22.4C6.70401 22.4 3.20001 18.096 3.20001 12.8C4.96766 12.8149 6.687 13.3787 8.1202 14.4135C9.5534 15.4483 10.6296 16.9028 11.2 18.576V11.2H7.20001C6.56349 11.2 5.95304 10.9472 5.50296 10.4971C5.05287 10.047 4.80001 9.43653 4.80001 8.80001V4.00001C4.80001 3.8321 4.85284 3.66845 4.95102 3.53224C5.04919 3.39603 5.18774 3.29416 5.34703 3.24106C5.50632 3.18796 5.67828 3.18633 5.83855 3.2364C5.99881 3.28646 6.13927 3.38568 6.24001 3.52001L8.68801 6.72001L11.328 1.92001C11.4005 1.80773 11.5 1.71541 11.6174 1.65149C11.7348 1.58757 11.8663 1.55408 12 1.55408C12.1337 1.55408 12.2652 1.58757 12.3826 1.65149C12.5 1.71541 12.5995 1.80773 12.672 1.92001L15.312 6.72001L17.76 3.52001C17.8608 3.38568 18.0012 3.28646 18.1615 3.2364C18.3217 3.18633 18.4937 3.18796 18.653 3.24106C18.8123 3.29416 18.9508 3.39603 19.049 3.53224C19.1472 3.66845 19.2 3.8321 19.2 4.00001V8.80001C19.2 9.43653 18.9472 10.047 18.4971 10.4971C18.047 10.9472 17.4365 11.2 16.8 11.2H12.8V18.576C13.3704 16.9028 14.4466 15.4483 15.8798 14.4135C17.313 13.3787 19.0324 12.8149 20.8 12.8V12.8Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Garden;
