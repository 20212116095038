import React from "react";
import Bath from "../assets/img/icon/Bath";
import Bed from "../assets/img/icon/Bed";
import Garden from "../assets/img/icon/Garden";
import Kitchen from "../assets/img/icon/Kitchen";
import Park from "../assets/img/icon/Park";
import Sofa from "../assets/img/icon/Sofa";

export const getIconName = (val) => {
  switch (val) {
    case "Ruang Tamu":
      return <Sofa />;
    case "Kamar Tidur":
      return <Bed />;
    case "Kamar Mandi":
      return <Bath />;
    case "Dapur":
      return <Kitchen />;
    case "Tempat Parkir":
      return <Park />;
    case "Taman":
      return <Garden />;
    default:
      return <Sofa />;
  }
};
