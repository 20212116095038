import React from "react";
import { Link } from "react-router-dom";
import Location from "../assets/img/icon/Location";
import Phone from "../assets/img/icon/Phone";
import Mail from "../assets/img/icon/Mail";
import Facebook from "../assets/img/icon/Facebook";
import Instagram from "../assets/img/icon/Instagram";
// import { facilityList } from "../utils/facilityList";
// import { allProducts } from "../utils/productList";

const Footer = () => {
  return (
    <footer className="section-colums">
      <div className="footer">
        <div className="container">
          <div className="contact-content">
            <div className="contact-eng">
              <div>
                <div className="menu-title">Kantor Pusat</div>
                <div className="footer-column">
                  <div className="footer-row">
                    <Location />
                    <div>
                      <div>
                        RT 08 RW 04 Dsn Sukorejo Ds Sukorejo <br />
                        Kecamatan Perak, Kabupaten Jombang <br />
                        Jawa Timur
                      </div>
                      <div className="google-maps">
                        <a
                          target="_blank"
                          rel="noreferrer noopener"
                          href="https://www.google.com/maps/place/Perumahan+Sativa+Podomoro/@-7.5518936,112.1816855,15z/data=!4m5!3m4!1s0x0:0xda29ca69852f95fd!8m2!3d-7.5518936!4d112.1816855"
                        >
                          Lihat di Google Maps
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="footer-row">
                    <Phone />
                    <a href="tel:085888073655"> 085888073655</a>
                  </div>
                  <div className="footer-row">
                    <Mail />
                    <a href="mailto:bumiagungpodomoro@gmail.com">
                      bumiagungpodomoro@gmail.com
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div className="menu-title">Social Media</div>
                <div className="footer-column">
                  <div className="footer-row">
                    <Instagram />
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.instagram.com/sativapodomoro/?hl=en"
                    >
                      {" "}
                      Sativa Podomoro
                    </a>
                  </div>
                  <div className="footer-row">
                    <Facebook />
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.facebook.com/sativa.podomoro.3"
                    >
                      Sativa Podomoro
                    </a>
                  </div>
                  <div className="footer-row">
                    <Instagram />
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.instagram.com/bapinteriorofficial"
                    >
                      {" "}
                      BAP Interior
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="footer-column">
                <Link to="/" className="footer-link">
                  Beranda
                </Link>
                <Link to="/perusahaan" className="footer-link">
                  Perusahaan
                </Link>
                <Link to="/bisnis" className="footer-link">
                  Bisnis
                </Link>
                <a
                  href="https://ppdpp.id/kalkulator-kpr-flpp/"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="footer-link"
                >
                  Simulasi KPR
                </a>
                <Link to="/kontak" className="footer-link">
                  Kontak
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        © Copyright {new Date().getFullYear()}, Bumi Agung Podomoro.{" "}
        {window.innerWidth < 600 && <br />} All Right Reserved
      </div>
    </footer>
  );
};

export default Footer;
