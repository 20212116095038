import axios from "axios";
import { API_URL } from "./constant";

const getKonten = async () => {
  const url = `${API_URL}/konten`;

  return await axios.get(url);
};

export default getKonten;
