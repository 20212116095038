import axios from "axios";
import { API_URL } from "./constant";

const getTestimoni = async () => {
  const url = `${API_URL}/testimoni`;

  return await axios.get(url);
};

export default getTestimoni;
