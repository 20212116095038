import React from "react";
import Icon from "./Icon";

const Bath = () => {
  return (
    <Icon>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 10H7V7.113C7 6.116 7.678 5.19 8.661 5.028C8.94813 4.97914 9.24245 4.99349 9.52347 5.07004C9.80448 5.1466 10.0654 5.28351 10.2881 5.47125C10.5108 5.65899 10.6898 5.89302 10.8128 6.15705C10.9357 6.42108 10.9996 6.70875 11 7H13C12.9995 6.4413 12.8821 5.88889 12.6553 5.3783C12.4284 4.86772 12.0972 4.41027 11.683 4.03539C11.2687 3.6605 10.7806 3.37649 10.25 3.20161C9.71933 3.02673 9.15797 2.96487 8.602 3.02C6.523 3.222 5 5.089 5 7.178V10H3C2.73478 10 2.48043 10.1054 2.29289 10.2929C2.10536 10.4804 2 10.7348 2 11V13C2 15.606 3.674 17.823 6 18.65V22H8V19H16V22H18V18.65C20.326 17.823 22 15.606 22 13V11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10V10Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Bath;
