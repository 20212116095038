import React from "react";
import Icon from "./Icon";

const Bed = () => {
  return (
    <Icon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.25 11C19.9503 11 20.6242 11.2671 21.1343 11.7469C21.6444 12.2267 21.9522 12.883 21.995 13.582L22 13.75V20.25C21.9999 20.44 21.9278 20.6229 21.798 20.7618C21.6683 20.9006 21.4907 20.9851 21.3011 20.998C21.1115 21.011 20.9241 20.9515 20.7767 20.8316C20.6293 20.7117 20.5329 20.5402 20.507 20.352L20.5 20.25V18H3.5V20.25C3.49999 20.4312 3.43436 20.6063 3.31523 20.7429C3.19611 20.8795 3.03155 20.9684 2.852 20.993L2.75 21C2.56876 21 2.39366 20.9344 2.25707 20.8152C2.12048 20.6961 2.03165 20.5316 2.007 20.352L2 20.25V13.75C1.99997 13.0497 2.26709 12.3758 2.74689 11.8657C3.22669 11.3556 3.88302 11.0478 4.582 11.005L4.75 11H19.25ZM6.75 4H17.25C17.9503 3.99997 18.6242 4.26709 19.1343 4.74689C19.6444 5.22669 19.9522 5.88302 19.995 6.582L20 6.75V10H17L16.993 9.883C16.9667 9.65978 16.8659 9.45194 16.707 9.29301C16.5481 9.13408 16.3402 9.03332 16.117 9.007L16 9H14C13.7551 9.00003 13.5187 9.08996 13.3356 9.25272C13.1526 9.41547 13.0357 9.63975 13.007 9.883L13 10H11L10.993 9.883C10.9667 9.65978 10.8659 9.45194 10.707 9.29301C10.5481 9.13408 10.3402 9.03332 10.117 9.007L10 9H8C7.75507 9.00003 7.51866 9.08996 7.33563 9.25272C7.15259 9.41547 7.03566 9.63975 7.007 9.883L7 10H4V6.75C3.99997 6.04971 4.26709 5.37579 4.74689 4.8657C5.22669 4.35561 5.88302 4.04778 6.582 4.005L6.75 4Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Bed;
