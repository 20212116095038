import React, { useRef, useState } from "react";
import { useEffect } from "react";
import getProyek from "../../api/get-proyek";
import Area from "../../assets/img/icon/Area";
import Home from "../../assets/img/icon/Home";
import Building from "../../assets/img/icon/Building";
import Customer from "../../assets/img/icon/Customer";
import CountUp from "react-countup";
import useOnScreen from "../../utils/useOnScreen";
import ProyekCard from "../proyek/ProyekCard";
import ProyekLoading from "../shared/ProyekLoading";

const LatestProject = () => {
  const lpRef = useRef(null);
  const lpVisible = useOnScreen(lpRef);

  const [proyek, setProyek] = useState({
    acf: {
      name: "",
      desc: "",
      lokasi: {
        address: "",
        kota: "",
        provinsi: "",
        keterangan: "",
      },
      proyek: {
        tipe_rumah: "",
        tahap: [],
      },
      galeri: [],
      header: {
        sizes: {
          medium_large: "",
        },
      },
      potrait: {
        sizes: {
          medium_large: "",
        },
      },
      fasilitas: [],
    },
  });
  const [lpCount, setLpCount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getProyek();

      setProyek(data.data[0]);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    !lpCount &&
      lpVisible &&
      setTimeout(() => {
        setLpCount(1);
      }, 3000);
  }, [lpVisible, lpCount]);

  return (
    <>
      <div className="lp-bg">
        {loading ? <ProyekLoading /> : <ProyekCard proyek={proyek} />}
      </div>
      <div className="konten-wrapper container" style={{ paddingBottom: 0 }}>
        <div className="video-responsive">
          <iframe
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/z8iCDdBA5Us`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </div>
      <div className="container" ref={lpRef}>
        <div className="section-best">
          <div className="section-title">
            Perumahan Subsidi Terbaik di <span> {proyek.acf.lokasi.kota}</span>
          </div>
          <div className="b-desc">{proyek.acf.keunggulan}</div>
          <div className="b-step">
            {proyek.acf.name} {proyek.acf.proyek.tahap[0]?.nama}
          </div>
          <div className="b-card">
            <div>
              <div className="b-card-content">
                <Customer />
                <div className="count">
                  {lpVisible && !lpCount ? (
                    <CountUp end={100} duration={2} />
                  ) : (
                    "100"
                  )}{" "}
                  %
                </div>
                <div className="label">Kepuasan Pelanggan</div>
                <div className="note">
                  Kami berkomitmen memberikan pelayanan terbaik untuk pelanggan.
                  <a
                    href="https://bit.ly/TESTIMONISATIVAPODOMORO"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="testi-link"
                  >
                    Kirimkan testimoni anda!
                  </a>
                </div>
              </div>
            </div>
            <div>
              <div className="b-card-content">
                <Area />
                <div className="count">
                  {lpVisible && !lpCount ? (
                    <CountUp
                      end={proyek.acf.proyek.tahap[0]?.luas_lahan || 0}
                      duration={2}
                    />
                  ) : (
                    proyek.acf.proyek.tahap[0]?.luas_lahan || 0
                  )}
                  ha
                </div>
                <div className="label">Luas Lahan</div>
              </div>
            </div>
            <div>
              <div className="b-card-content">
                <Home />
                <div className="count">
                  {lpVisible && !lpCount ? (
                    <CountUp
                      end={proyek.acf.proyek.tahap[0]?.unit_rumah}
                      duration={2}
                    />
                  ) : (
                    proyek.acf.proyek.tahap[0]?.unit_rumah || 0
                  )}
                </div>
                <div className="label">Unit Rumah</div>
              </div>
            </div>
            <div>
              <div className="b-card-content">
                <Building />

                <div className="count">
                  {lpVisible && !lpCount ? (
                    <CountUp
                      end={proyek.acf.proyek.tahap[0]?.unit_ruko}
                      duration={2}
                    />
                  ) : (
                    proyek.acf.proyek.tahap[0]?.unit_ruko || 0
                  )}
                </div>
                <div className="label">Unit Ruko</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestProject;
