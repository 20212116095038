import React from "react";
import { Link } from "react-router-dom";
import Left from "../assets/img/icon/Left";

const Header = ({ prefix, title, backLink, back }) => {
  return (
    <div className="header">
      <div className="container">
        <div className="heading">
          <div>
            {" "}
            <span className="prefix">{prefix}</span> <span>{title}</span>
          </div>
          {back && (
            <div className="back">
              <Left />
              <Link to={backLink}>{back}</Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
