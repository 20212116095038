import React from "react";
import Icon from "./Icon";

const Area = () => {
  return (
    <Icon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19ZM12 6H18V12H16V8H12V6ZM6 12H8V16H12V18H6V12Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Area;
