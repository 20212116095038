import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./page/Home";
import Kontak from "./page/Kontak";
import Proyek from "./page/Proyek";
import DetailProyek from "./page/DetailProyek";
import Perusahaan from "./page/Perusahaan";
import Bisnis from "./page/Bisnis";
// import ContactUs from "./page/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bisnis" element={<Bisnis />} />
        <Route path="/bisnis/:name" element={<Proyek />} />
        <Route path="/bisnis/:name/:id" element={<DetailProyek />} />
        <Route path="/kontak" element={<Kontak />} />
        <Route path="/perusahaan" element={<Perusahaan />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
