import React from "react";
import { Gallery as GalleryImage, Item } from "react-photoswipe-gallery";

const Gallery = ({ data }) => {
  return (
    <div className="container section-gallery" id="gallery">
      <span className="section-title">Galeri</span>
      <GalleryImage withCaption>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridTemplateRows: "1fr 1fr",
            gridGap: 24,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.galeri.map((g) => (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <Item
                alt={g.title}
                original={g.image.url}
                thumbnail={g.image.sizes.large}
                width={g.image.width}
                height={g.image.height}
                caption={`<h3 style="background-color:rgba(33, 68, 87, 0.5); padding:8px; margin: 16px 0px">${g.title}</h3>`}
              >
                {({ ref, open }) => (
                  <img
                    alt={g.title}
                    ref={ref}
                    onClick={open}
                    src={g.image.sizes.large}
                    style={{
                      cursor: "pointer",
                      objectFit: "cover",
                      width: "100%",
                      maxHeight: "100%",
                    }}
                  />
                )}
              </Item>
            </div>
          ))}
        </div>
      </GalleryImage>
    </div>
  );
};

export default Gallery;
