import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "animate.css";
import "swiper/scss";
import "photoswipe/dist/photoswipe.css";
import "./assets/styles/styles.scss";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
