import axios from "axios";
import { API_URL } from "./constant";

const getProyek = async () => {
  const url = `${API_URL}/proyek`;

  return await axios.get(url);
};

export default getProyek;
