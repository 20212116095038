import clsx from "clsx";
import React from "react";

const Icon = ({ fill, children, className }) => {
  return (
    <span
      className={clsx("icon", className)}
      style={{ fill: fill || "currentcolor" }}
    >
      {children}
    </span>
  );
};

export default Icon;
