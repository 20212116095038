import React from "react";
import Icon from "./Icon";

const Instagram = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_64:718)">
          <path
            d="M11.9971 7.99825C9.79357 7.99825 7.99535 9.79647 7.99535 12C7.99535 14.2034 9.79357 16.0017 11.9971 16.0017C14.2005 16.0017 15.9988 14.2034 15.9988 12C15.9988 9.79647 14.2005 7.99825 11.9971 7.99825ZM23.9992 12C23.9992 10.3428 24.0142 8.70073 23.9211 7.04661C23.8281 5.12531 23.3898 3.42016 21.9848 2.01521C20.5769 0.607257 18.8747 0.171963 16.9534 0.0788998C15.2963 -0.014163 13.6542 0.000847159 12.0001 0.000847159C10.3429 0.000847159 8.70083 -0.014163 7.04671 0.0788998C5.12542 0.171963 3.42026 0.610259 2.01531 2.01521C0.607364 3.42316 0.17207 5.12531 0.0790067 7.04661C-0.0140562 8.70373 0.000953971 10.3458 0.000953971 12C0.000953971 13.6541 -0.0140562 15.2992 0.0790067 16.9533C0.17207 18.8746 0.610366 20.5798 2.01531 21.9847C3.42327 23.3927 5.12542 23.8279 7.04671 23.921C8.70383 24.0141 10.3459 23.9991 12.0001 23.9991C13.6572 23.9991 15.2993 24.0141 16.9534 23.921C18.8747 23.8279 20.5799 23.3896 21.9848 21.9847C23.3928 20.5767 23.8281 18.8746 23.9211 16.9533C24.0172 15.2992 23.9992 13.6571 23.9992 12V12ZM11.9971 18.1571C8.58976 18.1571 5.8399 15.4073 5.8399 12C5.8399 8.59265 8.58976 5.84279 11.9971 5.84279C15.4044 5.84279 18.1542 8.59265 18.1542 12C18.1542 15.4073 15.4044 18.1571 11.9971 18.1571ZM18.4064 7.0286C17.6109 7.0286 16.9684 6.38616 16.9684 5.59062C16.9684 4.79509 17.6109 4.15265 18.4064 4.15265C19.2019 4.15265 19.8444 4.79509 19.8444 5.59062C19.8446 5.77953 19.8076 5.96662 19.7354 6.14119C19.6632 6.31576 19.5573 6.47438 19.4237 6.60795C19.2901 6.74153 19.1315 6.84744 18.957 6.91962C18.7824 6.9918 18.5953 7.02883 18.4064 7.0286V7.0286Z"
            fill="#228DAE"
          />
        </g>
        <defs>
          <clipPath id="clip0_64:718">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default Instagram;
