import React from "react";
import { getIconName } from "../../utils/getIconName";

const Fasilitas = ({ data }) => {
  return (
    <div className="section-fasilitas container">
      <div className="fc-flex">
        <div className="fc-proyek">
          <div className="fc-title">Detail Proyek</div>
          <div className="fc-list-grid">
            <div>
              <div className="fc-subtitle">Luas Tanah</div>
              <div>
                {data.proyek.tahap.map((thp) => (
                  <div key={thp.nama}>
                    {thp.nama}: {thp.luas_lahan} ha
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="fc-subtitle">Jumlah Unit</div>
              {data.proyek.tahap.map((thp, i) => (
                <div key={i}>
                  {thp.nama}: {thp.unit_rumah} rumah, {thp.unit_ruko} ruko
                </div>
              ))}
            </div>
            <div>
              <div className="fc-subtitle">Tipe Rumah</div>
              <div>{data.proyek.tipe_proyek}</div>
            </div>
            <div>
              <div className="fc-subtitle">Lokasi</div>
              <div>
                {data.lokasi.address} <br /> {data.lokasi.kota},{" "}
                {data.lokasi.provinsi} <br /> ({data.lokasi.keterangan}){" "}
              </div>
              <div className="google-maps">
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="https://www.google.com/maps/place/Perumahan+Sativa+Podomoro/@-7.5518936,112.1816855,15z/data=!4m5!3m4!1s0x0:0xda29ca69852f95fd!8m2!3d-7.5518936!4d112.1816855"
                >
                  Lihat di Google Maps
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="fc-rumah">
          <div className="fc-title">
            Tipe Rumah <span>{data.proyek.tipe_rumah}</span>
          </div>
          <div>
            <div className="icon-group">
              {data.fasilitas.map((type) => {
                return (
                  <p key={type.tipe}>
                    {getIconName(type.tipe)}
                    <span>{type.deskripsi}</span>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div className="fc-umum">
          <div className="fc-title">Fasilitas Umum</div>
          <div>
            <div className="icon-group">
              {data.fasilitas_umum.map((type) => {
                return <p key={type.nama}>- {type.nama}</p>;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fasilitas;
