import React, { useEffect, useState } from "react";
import getBisnis from "../../api/get-bisnis";
import getInterior from "../../api/get-interior";
import Description from "../proyek/Description";
import Header from "../proyek/Header";
import Gallery from "./Gallery";

const Interior = () => {
  const [loading, setLoading] = useState(false);
  const [interior, setInterior] = useState([]);
  const [bisnis, setBisnis] = useState({
    acf: {
      header: {
        sizes: {
          medium_large: "",
        },
      },
      logo: {
        sizes: {
          medium_large: "",
        },
      },
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = "Interior | Bumi Agung Podomoro";
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const [dataInterior, dataBisnis] = await Promise.all([
        getInterior(),
        getBisnis("interior"),
      ]);

      setInterior(dataInterior.data);
      setBisnis(dataBisnis.data[0]);
      setLoading(false);
    }
  }, []);

  return (
    <>
      <Header data={bisnis?.acf} loading={loading} type="interior" />
      <Description data={bisnis?.acf} loading={loading} type="interior" />
      <Gallery data={interior} loading={loading} />
    </>
  );
};

export default Interior;
