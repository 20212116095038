import React from "react";
import Icon from "./Icon";

const Mail = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6 8.84763V17.4C21.6 18.1957 21.284 18.9587 20.7213 19.5213C20.1587 20.084 19.3957 20.4 18.6 20.4H5.40002C4.60437 20.4 3.84131 20.084 3.2787 19.5213C2.71609 18.9587 2.40002 18.1957 2.40002 17.4V8.84763L11.6952 14.3172C11.7876 14.3717 11.8928 14.4004 12 14.4004C12.1072 14.4004 12.2125 14.3717 12.3048 14.3172L21.6 8.84763ZM18.6 4.80003C19.3382 4.79991 20.0505 5.07195 20.6007 5.56411C21.1508 6.05627 21.5002 6.734 21.582 7.46763L12 13.104L2.41802 7.46763C2.49981 6.734 2.84921 6.05627 3.39938 5.56411C3.94954 5.07195 4.66185 4.79991 5.40002 4.80003H18.6Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Mail;
