import React from "react";
import Icon from "./Icon";

const Kitchen = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9283 11.6286C23.1334 12.1414 22.884 12.7234 22.3712 12.9285L17.3712 14.9285C16.8584 15.1336 16.2764 14.8842 16.0713 14.3714C15.8662 13.8586 16.1156 13.2767 16.6284 13.0715L21.6284 11.0715C22.1412 10.8664 22.7231 11.1158 22.9283 11.6286Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.25345 13.3347C1.44319 13.1218 1.71481 13 2.00001 13H17C17.2852 13 17.5568 13.1218 17.7466 13.3347C17.9363 13.5476 18.0261 13.8314 17.9934 14.1147L17.7739 16.0156C17.6474 17.1125 17.122 18.1247 16.2977 18.8593C15.4734 19.594 14.4077 20 13.3035 20H5.69652C4.59232 20 3.52664 19.594 2.70233 18.8593C1.87804 18.1247 1.35264 17.1126 1.22611 16.0157L1.00661 14.1147C0.973893 13.8314 1.0637 13.5476 1.25345 13.3347Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 4C10.0523 4 10.5 4.44772 10.5 5V10C10.5 10.5523 10.0523 11 9.5 11C8.94772 11 8.5 10.5523 8.5 10V5C8.5 4.44772 8.94772 4 9.5 4Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 5C6.05228 5 6.5 5.44772 6.5 6V9C6.5 9.55228 6.05228 10 5.5 10C4.94772 10 4.5 9.55228 4.5 9V6C4.5 5.44772 4.94772 5 5.5 5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 5C14.0523 5 14.5 5.44772 14.5 6V9C14.5 9.55228 14.0523 10 13.5 10C12.9477 10 12.5 9.55228 12.5 9V6C12.5 5.44772 12.9477 5 13.5 5Z"
          fill="black"
        />
      </svg>
    </Icon>
  );
};

export default Kitchen;
