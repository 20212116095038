import axios from "axios";
import { API_URL } from "./constant";

const getBisnis = async (slug) => {
  const url = `${API_URL}/bisnis${slug ? `?slug=${slug}` : ""}`;

  return await axios.get(url);
};

export default getBisnis;
