import React from "react";
import { useEffect, useState } from "react";
import getTestimoni from "../../api/get-testimoni";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Left from "../../assets/img/icon/Left";
import Right from "../../assets/img/icon/Right";

const Testimoni = () => {
  const [testimoni, setTestimoni] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getTestimoni();

      setTestimoni(data.data);
      setLoading(false);
    }
  }, []);

  if (!testimoni.length) return null;

  return (
    <div>
      <div className="container">
        <div className="section-testimoni">
          <div className="section-title">Apa kata mereka?</div>
          <div className="sw-wrapper">
            <Swiper
              pagination={{
                clickable: true,
                el: ".swiper-pagination-progress",
              }}
              spaceBetween={32}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              slidesPerView={1}
              loop
              autoplay={{
                delay: 5000,
              }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              breakpoints={{
                760: {
                  slidesPerView: 2,
                  autoplay: false,
                  spaceBetween: 0,
                },
              }}
            >
              {loading && testimoni.length ? (
                <SwiperSlide>
                  <div className="t-slide-wrapper">
                    <div className="content-loader" style={{ height: 280 }} />
                  </div>
                </SwiperSlide>
              ) : (
                <>
                  {testimoni.map((data) => {
                    return (
                      <SwiperSlide key={data.id}>
                        <div className="t-slide-wrapper">
                          <div className="t-slide-box">
                            <img
                              src={data.acf.foto.sizes.thumbnail}
                              alt={`${data.acf.nama} testimoni`}
                            />
                            <div className="testi">{data.acf.testimoni}</div>
                            <div className="nama">{data.acf.nama}</div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </>
              )}

              <div className="swiper-pagination-progress testi-carousel"></div>

              <div className="swiper-button-next testi-carousel-next">
                <Right />
              </div>
              <div className="swiper-button-prev testi-carousel-prev">
                <Left />
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimoni;
