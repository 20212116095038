import React from "react";
import Icon from "./Icon";

const Customer = () => {
  return (
    <Icon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.726 13.02L14 16H9V15H13.065C13.1554 15 13.2442 14.9754 13.3218 14.9289C13.3993 14.8824 13.4629 14.8157 13.5055 14.736C13.5482 14.6563 13.5685 14.5664 13.5642 14.4761C13.5598 14.3858 13.5311 14.2983 13.481 14.223L12.593 12.891C12.4108 12.6168 12.1636 12.392 11.8735 12.2365C11.5833 12.081 11.2592 11.9998 10.93 12H3C2.73478 12 2.48043 12.1054 2.29289 12.2929C2.10536 12.4804 2 12.7348 2 13V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H13.639C14.0666 21.0001 14.4893 20.9088 14.8787 20.7322C15.2682 20.5556 15.6153 20.2977 15.897 19.976L22 13L20.548 12.516C20.073 12.3577 19.5658 12.3208 19.0729 12.4089C18.5799 12.4969 18.1169 12.707 17.726 13.02V13.02ZM19.258 7.39C19.709 6.925 19.988 6.282 19.988 5.572C19.988 4.862 19.709 4.219 19.258 3.754C19.0299 3.51598 18.7561 3.3265 18.453 3.19693C18.1498 3.06736 17.8237 3.00038 17.494 3C17.494 3 16.25 2.997 15 4.286C13.75 2.997 12.506 3 12.506 3C12.1764 3.00042 11.8504 3.06732 11.5473 3.19671C11.2442 3.32609 10.9703 3.5153 10.742 3.753C10.291 4.219 10.012 4.861 10.012 5.571C10.012 6.281 10.291 6.925 10.742 7.389L15 12L19.258 7.39Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Customer;
