import React from "react";
import Whatsapp from "../../assets/img/icon/Whatsapp";
import Button from "../shared/Button";
import LinkButton from "../shared/LinkButton";

const Header = ({ data, loading, type }) => {
  const goToGallery = () => {
    const id = document.getElementById("gallery");
    window.scroll({ top: id.offsetTop - 200, behavior: "smooth", left: 0 });
  };

  return (
    <div className="pd-header">
      <div className="img-container">
        <img src={data.header.sizes["post-thumbnail"]} alt={data.name} />
      </div>
      <div className="pd-header-overlay" />
      {!loading && (
        <div className="pd-header-name">
          {type === "interior" && (
            <img src={data.logo.sizes["thumbnail"]} alt="Logo" />
          )}
          <div className="pd-header-title">
            {type === "proyek" ? (
              <span>
                {" "}
                {data.name} - {data.lokasi.kota}
              </span>
            ) : (
              <span>{data.nama}</span>
            )}
          </div>
          <div className="pd-header-address">
            {type === "proyek" ? (
              <div>
                {data.lokasi.address} <br /> {data.lokasi.kota},{" "}
                {data.lokasi.provinsi} ({data.lokasi.keterangan})
              </div>
            ) : (
              <div> {data.tagline} </div>
            )}
          </div>
          {type === "proyek" && (
            <div className="button-group">
              <Button
                label="Lihat Galery"
                variant="outlined"
                onClick={goToGallery}
              />
              <LinkButton
                icon={<Whatsapp />}
                blank
                href="https://api.whatsapp.com/send?phone=6285888073655&text=Terima%20kasih%20telah%20menghubungi%20Perumahan%20Sativa%20Podomoro.%20Rumah%20Type%2047%2F66.%20Rumah%20Komersil%20Harga%20Subsidi.%0D%0APesan%20anda%20akan%20segera%20kami%20proses%20harap%20sabar%20menunggu%20balasan%20%F0%9F%98%8A%0D%0A%0D%0AKepuasan%20Anda%20Kebahagiaan%20kami%20Terimakasih%20%F0%9F%98%8A%0D%0A%0D%0ASativa%20Podomoro%0D%0AYour%20Living%20Residential%20%F0%9F%8C%BF"
                variant="primary"
                label="Hubungi Kami"
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
