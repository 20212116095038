import React from "react";
import Icon from "./Icon";

const Phone = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.2475 16.11L16.9275 14.43C17.1538 14.2065 17.4401 14.0536 17.7516 13.9896C18.0632 13.9257 18.3866 13.9537 18.6825 14.07L20.73 14.8875C21.0291 15.0089 21.2856 15.2161 21.4671 15.4831C21.6486 15.75 21.7471 16.0647 21.75 16.3875V20.1375C21.7483 20.3571 21.7021 20.574 21.6143 20.7753C21.5265 20.9766 21.3989 21.158 21.2392 21.3087C21.0794 21.4593 20.8908 21.5761 20.6847 21.6519C20.4786 21.7278 20.2593 21.7611 20.04 21.75C5.69254 20.8575 2.79754 8.70749 2.25004 4.05749C2.22462 3.82914 2.24785 3.598 2.31818 3.37927C2.38851 3.16055 2.50435 2.95919 2.65809 2.78844C2.81183 2.6177 2.99998 2.48145 3.21016 2.38864C3.42034 2.29583 3.64779 2.24858 3.87754 2.24999H7.50004C7.8233 2.25095 8.13887 2.34859 8.40619 2.53036C8.6735 2.71214 8.88031 2.96972 9.00004 3.26999L9.81754 5.31749C9.93773 5.61229 9.96839 5.93597 9.9057 6.2481C9.84301 6.56023 9.68974 6.84696 9.46504 7.07249L7.78504 8.75249C7.78504 8.75249 8.75254 15.3 15.2475 16.11Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Phone;
