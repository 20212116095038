import React from "react";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import getProyekSlug from "../api/get-proyek-slug";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Description from "../components/proyek/Description";
import Fasilitas from "../components/proyek/Fasilitas";
import Gallery from "../components/proyek/Gallery";
import Header from "../components/proyek/Header";
import Offer from "../components/shared/Offer";

const DetailProyek = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [proyek, setProyek] = useState({
    acf: {
      name: "",
      desc: "",
      lokasi: {
        address: "",
        kota: "",
        provinsi: "",
        keterangan: "",
      },
      proyek: {
        tipe_rumah: "",
        tahap: [],
      },
      galeri: [],
      header: {
        sizes: {
          medium_large: "",
        },
      },
      potrait: {
        sizes: {
          medium_large: "",
        },
      },
      fasilitas: [],
      fasilitas_umum: [],
    },
  });

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getProyekSlug(params.id);

      setProyek(data.data[0]);
      setLoading(false);
      document.title = `${data.data[0].acf.name} | Bumi Agung Podomoro `;
    }
  }, [params.id]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  if (!proyek) return null;

  return (
    <div>
      <Navbar />
      <Header data={proyek.acf} loading={loading} type="proyek" />
      <Description data={proyek.acf} loading={loading} type="proyek" />
      <Fasilitas data={proyek.acf} loading={loading} />
      <Gallery data={proyek.acf} loading={loading} />
      <Offer />
      <Footer />
    </div>
  );
};

export default DetailProyek;
