import React from "react";

const Intro = ({ konten }) => {
  if (!konten) return null;
  return (
    <section className="section-intro">
      <div className="container">
        <div className="section-title">
          Selamat datang di
          <span>PT. Bumi Agung Podomoro</span>
        </div>
        <div className="desc">{konten.acf.konten}</div>
      </div>
    </section>
  );
};

export default Intro;
