import axios from "axios";
import { API_URL } from "./constant";

const getProyekSlug = async (slug) => {
  const url = `${API_URL}/proyek?slug=${slug}`;

  return await axios.get(url);
};

export default getProyekSlug;
