import React from "react";
import Icon from "./Icon";

const Building = () => {
  return (
    <Icon>
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 2H7C6.46957 2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086 5 3.46957 5 4V21C5 21.2652 5.10536 21.5196 5.29289 21.7071C5.48043 21.8946 5.73478 22 6 22H18C18.2652 22 18.5196 21.8946 18.7071 21.7071C18.8946 21.5196 19 21.2652 19 21V4C19 3.46957 18.7893 2.96086 18.4142 2.58579C18.0391 2.21071 17.5304 2 17 2ZM11 16H8V14H11V16ZM11 12H8V10H11V12ZM11 8H8V6H11V8ZM16 16H13V14H16V16ZM16 12H13V10H16V12ZM16 8H13V6H16V8Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Building;
