import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import getHeader from "../api/get-header";
import Hero from "../components/home/Hero";
import Intro from "../components/home/Intro";
import getKonten from "../api/get-kontent";
import LatestProject from "../components/home/LatestProject";
import Testimoni from "../components/home/Testimoni";
import Offer from "../components/shared/Offer";
import Interior from "../components/home/Interior";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [header, setHeader] = useState([]);
  const [konten, setKonten] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const [header, konten] = await Promise.all([getHeader(), getKonten()]);

      setHeader(header.data);
      setKonten(konten.data);
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <Navbar scroll />

      <Hero data={header} loading={loading} />
      <Intro
        konten={konten?.find((data) => data.slug === "company-short-desc")}
      />
      <LatestProject />
      <Interior
        konten={konten?.find((data) => data.slug === "interior-description")}
        image={konten?.find((data) => data.slug === "interior-home-image")}
      />
      <Testimoni />
      <Offer />
      <Footer />
    </div>
  );
};

export default Home;
