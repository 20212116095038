import React from "react";
import Icon from "./Icon";

const Room = () => {
  return (
    <Icon>
      <svg
        viewBox="0 0 38 38"
        fill="inherit"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4496 4.01284C17.5597 3.92384 17.6884 3.86058 17.8261 3.82771C17.9639 3.79484 18.1072 3.79317 18.2457 3.82284L31.5457 6.67094C31.7578 6.71696 31.9478 6.83426 32.084 7.00331C32.2202 7.17235 32.2944 7.38295 32.2943 7.60004V30.4C32.2944 30.6174 32.2199 30.8282 32.0833 30.9973C31.9467 31.1664 31.7563 31.2835 31.5438 31.3291L18.2438 34.1791C18.1055 34.2085 17.9624 34.2067 17.825 34.1738C17.6875 34.1409 17.5591 34.0777 17.4492 33.9889C17.3392 33.9 17.2505 33.7878 17.1894 33.6603C17.1283 33.5328 17.0965 33.3933 17.0962 33.2519V4.75194C17.0962 4.61018 17.128 4.47023 17.1891 4.34234C17.2503 4.21445 17.3393 4.10186 17.4496 4.01284ZM23.75 19C23.75 18.8129 23.7131 18.6276 23.6415 18.4547C23.5699 18.2818 23.4649 18.1247 23.3326 17.9924C23.2003 17.8601 23.0432 17.7551 22.8703 17.6835C22.6974 17.6119 22.5121 17.575 22.325 17.575C22.1379 17.575 21.9526 17.6119 21.7797 17.6835C21.6068 17.7551 21.4497 17.8601 21.3174 17.9924C21.185 18.1247 21.0801 18.2818 21.0085 18.4547C20.9369 18.6276 20.9 18.8129 20.9 19C20.9 19.378 21.0501 19.7404 21.3174 20.0077C21.5846 20.2749 21.9471 20.425 22.325 20.425C22.7029 20.425 23.0654 20.2749 23.3326 20.0077C23.5999 19.7404 23.75 19.378 23.75 19Z"
          fill="inherit"
        />
        <path
          d="M15.2001 5.70361H6.64629C6.39433 5.70361 6.1527 5.8037 5.97454 5.98186C5.79638 6.16002 5.69629 6.40166 5.69629 6.65361V31.3441C5.69629 31.5961 5.79638 31.8377 5.97454 32.0159C6.1527 32.194 6.39433 32.2941 6.64629 32.2941H15.2001V5.70361Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Room;
