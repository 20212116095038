import React from "react";
import Room from "../../assets/img/icon/Room";
import Sofa from "../../assets/img/icon/Sofa";
import LinkButton from "../shared/LinkButton";

const Interior = ({ konten, image }) => {
  return (
    <div className="lp-bg section-interior">
      <div className="container">
        {konten ? (
          <div className="section-latest-project">
            <div className="lp-facility">
              <div className="section-title">
                <span
                  style={{ fontWeight: 400, color: "#828282", fontSize: 24 }}
                >
                  {" "}
                  Sustainable Furniture
                </span>{" "}
                <br />
                <span>BAP Interior Design & Furniture</span>
              </div>
              <div className="lp-desc">{konten.acf?.konten}</div>
              <div className="lp-loc">
                <div className="label">Lokasi</div>
                <div className="address">
                  Sukorejo Perak Kabupaten Jombang (5 menit dari Bravo)
                </div>
                <div className="google-maps">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.google.com/maps/place/Perumahan+Sativa+Podomoro/@-7.5518936,112.1816855,15z/data=!4m5!3m4!1s0x0:0xda29ca69852f95fd!8m2!3d-7.5518936!4d112.1816855"
                  >
                    Lihat di Google Maps
                  </a>
                </div>
              </div>
              <div className="lp-divider" />
              <div className="section-title">
                Produk <span>BAP Interior Design & Furniture</span>
              </div>
              <div className="interior-produk">
                <div className="room">
                  <Room />
                  <div className="title">Design interior</div>
                  <div className="sub">Residential atau Komersil</div>
                </div>
                <div>
                  <Sofa />
                  <div className="title">Furniture minimalis dan klasik</div>
                  <div className="sub">Custom atau Pengadaan</div>
                </div>
              </div>
            </div>
            <div className="lp-interior-image">
              {image.acf && (
                <img src={image.acf?.image.sizes.large} alt="Interior" />
              )}
              <LinkButton
                label="Lihat Koleksi Kami"
                variant="primary"
                href="/bisnis/interior"
                className="button-koleksi"
              />
            </div>
          </div>
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
};

export default Interior;
