import clsx from "clsx";
import React, { useRef } from "react";
import { getIconName } from "../../utils/getIconName";
import useOnScreen from "../../utils/useOnScreen";
import LinkButton from "../shared/LinkButton";

const ProyekCard = ({ proyek, list, short }) => {
  const lpPhotoRef = useRef(null);
  const lpPhotoVisible = useOnScreen(lpPhotoRef, "-100px");

  console.log(proyek);

  return (
    <div className="container">
      <div className="section-latest-project">
        <div className="lp-facility">
          <div className="section-title" style={{ fontSize: short ? 24 : 32 }}>
            {!short && (
              <>
                <span
                  style={{ fontWeight: 400, color: "#828282", fontSize: 24 }}
                >
                  {" "}
                  Proyek Terbaru{" "}
                </span>
                <br />
                PT. Bumi Agung Podomoro <br />
              </>
            )}
            <span>{proyek.acf.name}</span>
          </div>
          {list ? null : (
            <div className="lp-desc">{proyek.acf?.deskripsi_singkat}</div>
          )}
          <div className="lp-loc">
            <div className="label">Lokasi</div>
            <div className="address">
              {proyek.acf.lokasi.address} <br /> {proyek.acf.lokasi.kota},{" "}
              {proyek.acf.lokasi.provinsi} ({proyek.acf.lokasi.keterangan})
            </div>
            <div className="google-maps">
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.google.com/maps/place/Perumahan+Sativa+Podomoro/@-7.5518936,112.1816855,15z/data=!4m5!3m4!1s0x0:0xda29ca69852f95fd!8m2!3d-7.5518936!4d112.1816855"
              >
                Lihat di Google Maps
              </a>
            </div>
          </div>
          <div className="lp-divider"></div>
          <div>
            Tipe rumah <b>{proyek.acf.proyek.tipe_rumah}</b>
          </div>
          <div className="icon-group">
            {proyek.acf.fasilitas.map((type) => {
              return (
                <p key={type.tipe}>
                  {getIconName(type.tipe)}
                  <span>{type.deskripsi}</span>
                </p>
              );
            })}
          </div>
          <div className="lp-button">
            <LinkButton
              href={`/bisnis/proyek/${proyek.slug}`}
              variant="primary"
              label="Lihat Proyek"
            />
          </div>
        </div>
        <div
          ref={lpPhotoRef}
          className={clsx(
            "lp-image-grid",
            lpPhotoVisible &&
              "show animate__animated animate__fadeIn animate__slow"
          )}
        >
          <div
            className="lp-grid-1"
            style={{
              backgroundImage: `url("${proyek.acf.header.sizes.medium_large}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          <div
            className="lp-grid-2"
            style={{
              backgroundImage: `url("${proyek.acf.potrait.sizes.medium_large}")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          ></div>
          {proyek.acf.galeri.length && (
            <div
              className="lp-grid-3"
              style={{
                backgroundImage: `url("${proyek.acf.galeri[0].image.sizes.medium_large}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProyekCard;
