import React from "react";
import { Gallery as GalleryImage, Item } from "react-photoswipe-gallery";

const Gallery = ({ data = [], loading }) => {
  return (
    <div className="container section-gallery" id="gallery">
      <span className="section-title">Proyek Terbaru</span>
      {loading ? (
        "loading"
      ) : (
        <div>
          {data.map((interior) => (
            <div style={{ marginBottom: 32 }}>
              <div className="section-image-title">{interior.acf.nama}</div>
              <GalleryImage withCaption>
                <div
                  className="section-image-grid"
                  style={{
                    display: "grid",
                    gridGap: 24,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {interior.acf.galeri.map((g) => (
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Item
                        original={g.url}
                        thumbnail={g.sizes.medium}
                        width={g.width}
                        height={g.height}
                        caption={interior.acf.nama}
                      >
                        {({ ref, open }) => (
                          <img
                            ref={ref}
                            alt={interior.acf.nama}
                            onClick={open}
                            src={g.sizes.medium}
                            style={{
                              cursor: "pointer",
                              objectFit: "cover",
                              width: "100%",
                              maxHeight: "100%",
                            }}
                          />
                        )}
                      </Item>
                    </div>
                  ))}
                </div>
              </GalleryImage>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Gallery;
