import React from "react";
import Icon from "./Icon";

const Park = () => {
  return (
    <Icon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 14H12.5C13.4283 14 14.3185 13.6313 14.9749 12.9749C15.6313 12.3185 16 11.4283 16 10.5C16 9.57174 15.6313 8.6815 14.9749 8.02513C14.3185 7.36875 13.4283 7 12.5 7H9V17H11V14ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3V3ZM11 9H12.5C12.8978 9 13.2794 9.15804 13.5607 9.43934C13.842 9.72064 14 10.1022 14 10.5C14 10.8978 13.842 11.2794 13.5607 11.5607C13.2794 11.842 12.8978 12 12.5 12H11V9Z"
          fill="inherit"
        />
      </svg>
    </Icon>
  );
};

export default Park;
