import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import Header from "../components/Header";
import { useState } from "react";
import getMarketing from "../api/get-marketing";
import Offer from "../components/shared/Offer";

const About = () => {
  const [loading, setLoading] = useState(false);
  const [marketing, setMarketing] = useState([]);

  useEffect(() => {
    setLoading(true);
    getAPI();
    async function getAPI() {
      const data = await getMarketing();

      setMarketing(data.data);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = "Kontak | Bumi Agung Podomoro";
  }, []);

  return (
    <div>
      <Navbar />
      <Header prefix="Kontak Marketing" title="PT. Bumi Agung Podomoro" />
      <section className="container">
        <div className="kontak konten-wrapper">
          {loading ? (
            <>
              {" "}
              <div className="content-loader" />{" "}
              <div className="content-loader" />{" "}
              <div className="content-loader" />{" "}
            </>
          ) : (
            marketing.map((data) => (
              <div key={data.id}>
                <div className="box">
                  <img
                    src={data.acf.foto.sizes["thumbnail"]}
                    alt="data.acf.nama"
                  />
                  <div className="name">{data.acf.nama}</div>
                  <div>{data.acf.email}</div>
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ textDecoration: "underline" }}
                    href={`https://api.whatsapp.com/send?phone=62${data.acf.kontak.substring(
                      1
                    )}&text=Terima%20kasih%20telah%20menghubungi%20Perumahan%20Sativa%20Podomoro.%20Rumah%20Type%2047%2F66.%20Rumah%20Komersil%20Harga%20Subsidi.%0D%0APesan%20anda%20akan%20segera%20kami%20proses%20harap%20sabar%20menunggu%20balasan%20%F0%9F%98%8A%0D%0A%0D%0AKepuasan%20Anda%20Kebahagiaan%20kami%20Terimakasih%20%F0%9F%98%8A%0D%0A%0D%0ASativa%20Podomoro%0D%0AYour%20Living%20Residential%20%F0%9F%8C%BF`}
                  >
                    {data.acf.kontak}
                  </a>
                </div>
              </div>
            ))
          )}
        </div>
      </section>
      <Offer />
      <Footer />
    </div>
  );
};

export default About;
