// import Swiper core and required modules
import React from "react";
import clsx from "clsx";
import { Navigation, Pagination, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Left from "../../assets/img/icon/Left";
import Right from "../../assets/img/icon/Right";
import LinkButton from "../shared/LinkButton";

const Hero = ({ data, loading }) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, A11y, Autoplay]}
      pagination={{
        clickable: true,
        el: ".swiper-pagination",
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
      slidesPerView={1}
      loop
      initialSlide={1}
      autoplay={{ delay: 5000 }}
    >
      {loading && !data?.length ? (
        <SwiperSlide>
          <div
            className="content-loader"
            style={{ width: "100%", height: "100vh" }}
          />
        </SwiperSlide>
      ) : (
        data.map((img, i) => {
          return (
            <SwiperSlide key={i}>
              <section className={clsx("hero")}>
                <div className="img-container">
                  <img
                    src={img.acf.gambar.sizes["post-thumbnail"]}
                    alt={img.acf.gambar.title}
                    className="animate__animated animate__fadeIn animate__slow"
                  />
                </div>

                {img.acf.judul && (
                  <div className="brand">
                    <div className="hero-box">
                      <div className="title">{img.acf.judul}</div>
                      <div className="motto">{img.acf.deskripsi}</div>
                      {img.acf.link && (
                        <LinkButton
                          label="Lihat Lebih Lanjut"
                          variant="primary"
                          className="hero-button"
                          href={img.acf.link}
                        />
                      )}
                    </div>
                  </div>
                )}
              </section>
            </SwiperSlide>
          );
        })
      )}
      <div className="swiper-pagination hero-carousel"></div>

      <div className="swiper-button-next jr-carousel-next">
        <Right />
      </div>
      <div className="swiper-button-prev jr-carousel-prev">
        <Left />
      </div>
    </Swiper>
  );
};

export default Hero;
