import React from "react";
import { useEffect, useState } from "react";
import getKonten from "../api/get-kontent";
import getProyek from "../api/get-proyek";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProyekCard from "../components/proyek/ProyekCard";
import Offer from "../components/shared/Offer";
import ProyekLoading from "../components/shared/ProyekLoading";

const Perusahaan = () => {
  const [konten, setKonten] = useState("");
  const [loading, setLoading] = useState(true);
  const [proyek, setProyek] = useState({
    acf: {
      name: "",
      desc: "",
      lokasi: {
        address: "",
        kota: "",
        provinsi: "",
        keterangan: "",
      },
      proyek: {
        tipe_rumah: "",
        tahap: [],
      },
      galeri: [],
      header: {
        sizes: {
          medium_large: "",
        },
      },
      potrait: {
        sizes: {
          medium_large: "",
        },
      },
      fasilitas: [],
    },
  });

  useEffect(() => {
    window.scrollTo({ top: 0 });

    document.title = "Perusahaan | Bumi Agung Podomoro";
  }, []);

  useEffect(() => {
    setLoading(true);
    getAPI();

    async function getAPI() {
      const [konten, proyek] = await Promise.all([getKonten(), getProyek()]);

      setKonten(konten.data.find((data) => data.slug === "company-short-desc"));
      setProyek(proyek.data[0]);
      setLoading(false);
    }
  }, []);
  console.log(konten);
  return (
    <div>
      <Navbar />
      <Header prefix="Tentang" title="PT. Bumi Agung Podomoro" />
      <div className="container">
        <div className="konten-wrapper">
          {loading ? (
            <div
              className="content-loader"
              style={{ height: 300, marginBottom: 80 }}
            ></div>
          ) : (
            konten?.acf?.konten
          )}
        </div>
      </div>
      <div className="konten-wrapper">
        {loading ? <ProyekLoading /> : <ProyekCard proyek={proyek} />}
      </div>
      <Offer />
      <Footer />
    </div>
  );
};

export default Perusahaan;
