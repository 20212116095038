import axios from "axios";
import { API_URL } from "./constant";

const getMarketing = async () => {
  const url = `${API_URL}/marketing`;

  return await axios.get(url);
};

export default getMarketing;
