import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../shared/LinkButton";

const BisnisCard = ({ data, slug, type }) => {
  return (
    <div className="bisnis-card">
      <div>
        <div className="content">
          <Link to={`/bisnis/${slug}`} className="title">
            {data.nama}
          </Link>
          <div className="tagline">{data.tagline}</div>
          <div className="desc">{data.deskripsi}</div>
          <LinkButton
            href={`/bisnis/${slug}${
              slug === "proyek" ? "/sativa-podomoro" : ""
            }`}
            variant="primary"
            label={data.label_tombol}
          />
        </div>
      </div>
      <div
        className="bisnis-image"
        style={{
          backgroundImage: `url("${data.header.sizes.medium_large}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      />
    </div>
  );
};

export default BisnisCard;
